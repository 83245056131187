import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import BackgroundBalls from '../../components/BackgroundBalls'
import useBillingApi from '../../api/useBillingApi'
import EmptyContent from '../../components/EmptyContent'
import BillingAdminTabs from './BillingAdminTabs'

export default function InvoiceAgreementOverview() {
  const navigate = useNavigate()
  const { getInvoiceAgreements } = useBillingApi()

  const [invoiceAgreements, setInvoiceAgreements] = useState()
  const [inProgress, setInProgress] = useState()

  useEffect(() => {
    getInvoiceAgreements(null, null, null, null, setInProgress, setInvoiceAgreements)
  }, [])

  function onClickAction(event, customerIdentifier, identifier) {
    const path = `/customer/${customerIdentifier}?activeTab=billing&subTab=invoiceAgreement&invoiceAgreementIdentifier=${identifier}`
    if (event.metaKey || event.ctrlKey || event.type === 'auxclick') {
      window.open(path, '_blank', 'noopener,noreferrer')
    } else {
      navigate(path)
    }
  }

  return (
    <div>
      <BackgroundBalls styleClassed="opacity-1" />
      <section className="hero is-hero-bar">
        <p className="title">Billing</p>
      </section>
      <div className="section">
        <BillingAdminTabs />
        {!inProgress && invoiceAgreements && (
          <div className="table-wrapper">
            <table
              className={`table is-fullwidth is-striped is-bordered ${
                invoiceAgreements?.length > 0 ? 'is-hoverable' : ''
              }`}
            >
              {invoiceAgreements?.length > 0 && (
                <thead>
                  <tr>
                    <th>Customer name</th>
                    <th className="width-8">Interval</th>
                    <th className="width-8">Start date</th>
                    <th className="width-8">End date</th>
                    <th className="width-15">Types</th>
                    <th className="width-15">PO number</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {invoiceAgreements?.map((invoiceAgreement) => (
                  <tr
                    className="is-clickable"
                    key={invoiceAgreement.identifier}
                    onClick={(e) =>
                      onClickAction(
                        e,
                        invoiceAgreement.customerIdentifier,
                        invoiceAgreement.identifier
                      )
                    }
                    onAuxClick={(e) =>
                      onClickAction(
                        e,
                        invoiceAgreement.customerIdentifier,
                        invoiceAgreement.identifier
                      )
                    }
                  >
                    <td>{invoiceAgreement.customerName}</td>
                    <td>{invoiceAgreement.invoiceInterval}</td>
                    <td>{invoiceAgreement.startDate}</td>
                    <td>{invoiceAgreement.endDate}</td>
                    <td>{invoiceAgreement.types.join(', ')}</td>
                    <td>{invoiceAgreement.poNumber}</td>
                  </tr>
                ))}
                {invoiceAgreements?.length === 0 && (
                  <tr>
                    <td>
                      <EmptyContent text="Sorry, this box is empty for you. There are no actions found." />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}
