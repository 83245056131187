import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat'
import BackgroundBalls from '../../components/BackgroundBalls'
import InputPassword from '../../components/InputPassword'
import { UserAuth } from '../../contexts/AuthContext'
import Icon from '../../components/Icon'

export default function ResetPasswordView() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { isLoading, confirmThePasswordReset, user } = UserAuth()
  const [error, setError] = useState('')
  const [password, setPassword] = useState('')

  const handleConfirmThePasswordReset = async () => {
    setError('')
    try {
      await confirmThePasswordReset(searchParams.get('oobCode'), password)
      navigate('/sign-in?redirectTo=/', { replace: true })
    } catch (e) {
      switch (e.code) {
        case 'auth/invalid-action-code':
          setError('The url is incorrect. Please use the url send by email.')
          break
        case 'auth/user-not-found':
          setError('invalid username/password')
          break
        case 'auth/invalid-email':
          setError('Email address is not valid')
          break
        default:
          setError(e.message)
          break
      }
    }
  }

  return (
    <>
      <BackgroundBalls />
      {!isLoading && (
        <div>
          <div className="columns is-flex is-vcentered is-full-height">
            <div className="column">&nbsp;</div>
            <div className="column is-5-desktop is-11-mobile is-8-tablet">
              <div className="card">
                <div className="card-content p-5">
                  <div className="content">
                    <p className="title">Reset Gordium® password</p>
                    {!searchParams.has('oobCode') && (
                      <p className="has-text-warning">
                        The url is incorrect. Please use the url send by email.
                      </p>
                    )}
                    {!user && !isLoading && searchParams.has('oobCode') && (
                      <>
                        {error && <p className="has-text-warning">{error}</p>}
                        <div className="field">
                          <label className="label">Password</label>
                          <div className="control">
                            <InputPassword
                              className="input"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="buttons is-right mt-4">
                          <button
                            type="button"
                            className="button is-ghost"
                            onClick={() => navigate('/')}
                          >
                            <Icon
                              icon="ic:baseline-arrow-back"
                              extraOuterClasses="has-text-primary-dark-blue"
                            />
                            <span>Back</span>
                          </button>
                          <button
                            type="button"
                            className="button is-rounded is-primary"
                            onClick={handleConfirmThePasswordReset}
                          >
                            <span>Reset password</span>
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="column">&nbsp;</div>
          </div>
        </div>
      )}
    </>
  )
}
