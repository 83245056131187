import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import EmptyContent from '../../components/EmptyContent'
import BackgroundBalls from '../../components/BackgroundBalls'
import useSmartHazopApi from '../../api/useSmartHazopApi'

export default function LibraryOverview() {
  const { getLibraries } = useSmartHazopApi()

  const navigate = useNavigate()
  const [libraries, setLibraries] = useState()

  useEffect(() => {
    getLibraries(null, setLibraries)
  }, [])

  function onClickLibrary(event, identifier) {
    const path = `/library/${identifier}`
    if (event.metaKey || event.ctrlKey || event.type === 'auxclick') {
      window.open(path, '_blank', 'noopener,noreferrer')
    } else {
      navigate(path)
    }
  }

  return (
    <div>
      <BackgroundBalls styleClassed="opacity-1" />
      <section className="hero is-hero-bar">
        <p className="title">Knowledge Graphs</p>
      </section>
      <section className="section">
        <div className="table-wrapper">
          {libraries?.length > 0 && (
            <table className="table is-fullwidth is-striped is-hoverable is-bordered">
              <thead>
                <tr>
                  <th className="width-20">Name</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {libraries?.map((library) => (
                  <tr
                    className="is-clickable"
                    key={library.identifier}
                    onClick={(e) => onClickLibrary(e, library.identifier)}
                    onAuxClick={(e) => onClickLibrary(e, library.identifier)}
                  >
                    <td>{library.name}</td>
                    <td>{library.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {libraries?.length === 0 && (
            <EmptyContent text="Sorry, this box is empty for you. There are no libraries found." />
          )}
        </div>
      </section>
    </div>
  )
}
