import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat'
import Icon from '../../components/Icon'

export default function BillingAdminTabs() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const updateSubview = (event, subview) => {
    if (subview === 'invoice') {
      searchParams.set('state', 'InPreparation')
    } else {
      searchParams.delete('state')
    }
    if (event.metaKey || event.ctrlKey || event.type === 'auxclick') {
      window.open(
        `${window.location.origin}/billing/${subview}?${searchParams.toString()}`,
        '_blank',
        'noopener,noreferrer'
      )
    } else {
      navigate(`/billing/${subview}?${searchParams.toString()}`)
    }
  }

  return (
    <div className="tabs is-toggle">
      <ul>
        <li
          className={`${location.pathname === '/billing/generate-invoices' ? 'is-active' : ''}`}
          onClick={(event) => updateSubview(event, 'generate-invoices')}
          onAuxClick={(event) => updateSubview(event, 'generate-invoices')}
        >
          <a>
            <Icon icon="material-symbols:line-start-diamond-outline" />
            <span className="is-hidden-mobile">Generate invoices</span>
          </a>
        </li>
        <li
          className={`${location.pathname === '/billing/open-invoice-line' ? 'is-active' : ''}`}
          onClick={(event) => updateSubview(event, 'open-invoice-line')}
          onAuxClick={(event) => updateSubview(event, 'open-invoice-line')}
        >
          <a>
            <Icon icon="ic:outline-folder-open" />
            <span className="is-hidden-mobile">Open invoice lines</span>
          </a>
        </li>
        <li
          className={`${location.pathname === '/billing/invoice' ? 'is-active' : ''}`}
          onClick={(event) => updateSubview(event, 'invoice')}
          onAuxClick={(event) => updateSubview(event, 'invoice')}
        >
          <a>
            <Icon icon="ic:outline-attach-money" />
            <span className="is-hidden-mobile">Invoices</span>
          </a>
        </li>
        <li
          className={`${location.pathname === '/billing/invoice-agreement' ? 'is-active' : ''}`}
          onClick={(event) => updateSubview(event, 'invoice-agreement')}
          onAuxClick={(event) => updateSubview(event, 'invoice-agreement')}
        >
          <a>
            <Icon icon="ic:outline-handshake" />
            <span className="is-hidden-mobile">Invoice agreements</span>
          </a>
        </li>
      </ul>
    </div>
  )
}
