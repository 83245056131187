import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat'

import ResetPasswordView from './ResetPasswordView'
import { UserAuth } from '../../contexts/AuthContext'

export default function AuthenticationActionView() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { handleActionCode } = UserAuth()
  const [errorMessage, setErrorMessage] = useState()

  useEffect(async () => {
    if (searchParams.has('mode') && searchParams.has('oobCode')) {
      if (
        searchParams.get('mode') === 'verifyEmail' ||
        searchParams.get('mode') === 'revertSecondFactorAddition'
      ) {
        try {
          await handleActionCode(searchParams.get('oobCode'))
          if (searchParams.has('continueUrl')) {
            window.location.href = searchParams.get('continueUrl')
          } else {
            navigate('/')
          }
        } catch (e) {
          switch (e.code) {
            case 'auth/invalid-action-code':
              setErrorMessage('The url used is not valid. Try to request a new url.')
              break
            default:
              setErrorMessage(e.message)
              break
          }
        }
      }
    }
  }, [])

  if (!searchParams.has('mode')) {
    return 'This page expects a mode query parameter'
  }
  if (searchParams.get('mode') === 'verifyEmail') {
    return <div>{errorMessage && <p className="has-text-danger">{errorMessage}</p>}</div>
  }
  if (searchParams.get('mode') === 'revertSecondFactorAddition') {
    return <div>{errorMessage && <p className="has-text-danger">{errorMessage}</p>}</div>
  }
  if (searchParams.get('mode') === 'resetPassword') {
    return <ResetPasswordView />
  }
}
