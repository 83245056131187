import * as React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { UserAuth } from '../contexts/AuthContext'
import ShowLoginExpirePreventorButton from './ShowLoginExpirePreventorButton'
import Profile from './Profile'
import TrialInformation from '../views/billing/TrialInformation'

export default function Nav({ setForceShowLoginRefresh }) {
  const [navbarMenuActive, setNavbarMenuActive] = useState(false)
  const { isAuthenticated } = UserAuth()
  const toggleNavbarMenuActive = () => {
    setNavbarMenuActive((prevState) => !prevState)
  }

  return (
    isAuthenticated && (
      <nav className="navbar">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img src="/logo-gordium-landscape-transparent-no-padding.svg" alt="Gordium logo" />
          </Link>

          <Link
            onClick={() => toggleNavbarMenuActive()}
            to="#"
            className={`navbar-burger ${navbarMenuActive ? 'is-active' : ''}`}
            data-target="navbar-menu"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </Link>
        </div>
        <div
          className={`navbar-menu fadeIn animated faster ${navbarMenuActive ? 'is-active' : ''}`}
          id="navbar-menu"
        >
          <div className="navbar-end">
            <TrialInformation />
            <Profile setNavbarMenuActive={setNavbarMenuActive} />
            <ShowLoginExpirePreventorButton setForceShowLoginRefresh={setForceShowLoginRefresh} />
          </div>
        </div>
      </nav>
    )
  )
}
