import React from 'react'
import NoConnection from '../components/NoConnection'

const bookmarks = []
const appVersion = process.env.REACT_APP_VERSION

async function executeRequest(
  url,
  method,
  data,
  getAccessTokenSilently,
  addToast,
  setInProgress,
  handleSaveSuccess,
  navigate,
  updateRoles
) {
  if (setInProgress) {
    setInProgress(true)
  }
  const token = await getAccessTokenSilently()

  function updateBookmarks(response) {
    if (response.headers.get('X-smarthazop-bookmark')) {
      const newBookmarks = response.headers.get('X-smarthazop-bookmark')?.split(', ')
      newBookmarks?.forEach((bookmark) => bookmarks.push({ bookmark, ttl: Date.now() + 5 * 1000 }))
    }
  }

  function updateRolesFromHeader(response) {
    if (updateRoles && response.headers.get('X-smarthazop-user-roles')) {
      updateRoles(response.headers.get('X-smarthazop-user-roles')?.split(', '))
    }
  }

  function updateApplicationVersion(response) {
    if (response.headers.get('X-smarthazop-application-version')) {
      const applicationVersion = response.headers.get('X-smarthazop-application-version')
      if (appVersion !== 'development' && applicationVersion !== appVersion && addToast) {
        addToast({
          level: 'is-info',
          type: 'is-permanent',
          key: 'new-version',
          message: (
            <div className="content">
              <ul>
                <li>
                  There is a new version of the application available.{' '}
                  <a onClick={() => location.reload()}>Please refresh your page</a>.
                </li>
              </ul>
            </div>
          )
        })
      }
    }
  }

  const getBookmarks = () => {
    const currentTime = Date.now()
    const filteredBookmarks = bookmarks.filter((item) => item.ttl >= currentTime)
    bookmarks.length = 0
    filteredBookmarks.forEach((bookmark) => bookmarks.push(bookmark))
    return filteredBookmarks.map((item) => item.bookmark)
  }

  return fetch(url, {
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
          'X-smarthazop-bookmark': getBookmarks()
        }
      : {},
    method,
    body: data ? JSON.stringify(data) : null
  })
    .then((response) => {
      if (response.ok) {
        updateBookmarks(response)
        updateApplicationVersion(response)
        updateRolesFromHeader(response)
        if (
          response.headers.get('content-type') &&
          response.headers.get('content-type').indexOf('application/json') !== -1
        ) {
          if (handleSaveSuccess) {
            return response.json().then((json) => {
              handleSaveSuccess(json)
            })
          } else {
            return response.json()
          }
        } else if (handleSaveSuccess) {
          handleSaveSuccess()
        }
      } else {
        const contentType = response.headers.get('content-type')
        if (response.status === 401 && addToast) {
          if (addToast && contentType && contentType.indexOf('application/json') !== -1) {
            response.json().then((json) => {
              if (json.code && json.code === '') {
                addToast({
                  level: 'is-warning',
                  message: (
                    <div className="content">
                      <ul>
                        {json.map((item) => (
                          <li>
                            {item.message} {item.value ? `: ${item.value}` : ''}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                })
              } else {
                addToast({
                  level: 'is-warning',
                  message: json.message
                })
              }
            })
          }
        } else if (addToast && contentType && contentType.indexOf('application/json') !== -1) {
          return response.json().then((json) => {
            addToast({
              level: 'is-warning',
              message: (
                <div className="content">
                  <ul>
                    {json.map((item) => (
                      <li>
                        {item.message} {item.value ? `: ${item.value}` : ''}
                      </li>
                    ))}
                  </ul>
                </div>
              )
            })
          })
        } else if (response.status === 403 || response.status === 404) {
          if (navigate) {
            navigate('/error')
          } else if (addToast) {
            addToast({
              level: 'is-danger',
              message:
                "The resource doesn't exist, or you don't have permission to perform this action"
            })
          }
        } else if (addToast) {
          addToast({
            level: 'is-danger',
            message: 'There was a problem while processing the request, please try again later.'
          })
        }
      }
    })
    .catch((reason) => {
      console.log(reason)
      if (addToast) {
        addToast({
          level: '',
          message: <NoConnection />
        })
      }
    })
    .finally(() => {
      if (setInProgress) {
        setInProgress(false)
      }
    })
}

export default async function save(
  url,
  data,
  getAccessTokenSilently,
  addToast,
  setInProgress,
  handleSaveSuccess,
  navigate
) {
  return executeRequest(
    url,
    'POST',
    data,
    getAccessTokenSilently,
    addToast,
    setInProgress,
    handleSaveSuccess,
    navigate
  )
}

export async function deleteRequest(
  url,
  data,
  getAccessTokenSilently,
  addToast,
  setInProgress,
  handleSaveSuccess,
  navigate
) {
  return executeRequest(
    url,
    'DELETE',
    data,
    getAccessTokenSilently,
    addToast,
    setInProgress,
    handleSaveSuccess,
    navigate
  )
}

export async function get(
  url,
  getAccessTokenSilently,
  addToast,
  setInProgress,
  handleSuccess,
  navigate,
  updateRoles
) {
  return executeRequest(
    url,
    'GET',
    undefined,
    getAccessTokenSilently,
    addToast,
    setInProgress,
    handleSuccess,
    navigate,
    updateRoles
  )
}
