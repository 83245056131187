import React from 'react'
import ReactDOM from 'react-dom'
import './scss/main.scss'
import { BrowserRouter } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import App from './App'
import { AuthContextProvider } from './contexts/AuthContext'

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <BrowserRouter>
        <CompatRouter>
          <App />
        </CompatRouter>
      </BrowserRouter>
    </AuthContextProvider>
  </React.StrictMode>,

  document.getElementById('root')
)
